import { z } from 'zod'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { requiredCurrencyCode, requiredString } from '~/schemas/util'
import { requiredIBAN } from './util/required-iban'
import { requiredBIC } from './util/required-bic'

// Create bank account
export const CreateBankAccountSchema = z.object({
    name: requiredString(),
    bankName: z.string().default(''),
    iban: requiredIBAN(),
    bic: requiredBIC(),
    currencyCode: requiredCurrencyCode(),
    isDefault: z.boolean().default(false),
})

export const BankAccountSchema = BaseEntitySchema.extend(
    CreateBankAccountSchema.shape,
)

export type BankAccountResponse = z.infer<typeof BankAccountSchema>
export type CreateBankAccountRequest = z.infer<typeof CreateBankAccountSchema>
export type UpdateBankAccountRequest = z.infer<typeof CreateBankAccountSchema>
