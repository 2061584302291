import {
    BaseCreateRevenueLineItemSchema,
    BaseRevenueLineItemSchema,
} from '~/schemas/base/base-revenue'
import type { z } from 'zod'

export const CreateInvoiceLineItemSchema = BaseCreateRevenueLineItemSchema
export const InvoiceLineItemSchema = BaseRevenueLineItemSchema

export type InvoiceLineItemResponse = z.infer<typeof InvoiceLineItemSchema>

export type CreateInvoiceLineItemRequest = z.infer<
    typeof CreateInvoiceLineItemSchema
>

export type UpdateInvoiceLineItemRequest = z.infer<
    typeof CreateInvoiceLineItemSchema
>
