import { z } from 'zod'
import { ExpenseDocumentStatus } from '@prisma/client'
import { OrderDirection, type PaginationQuery } from '~/schemas/common'
import {
    createFiltersSchema,
    createOrderSchema,
    createPaginatedResponseSchema,
    requiredDateTime,
    requiredUuid,
} from '~/schemas/util'
import { ExpenseSchema } from './expense'

export const ExpenseOrderSchema = createOrderSchema(
    z.enum(['contact.name', 'issueDate', 'totalInBaseCurrency', 'createdAt']),
    'createdAt',
    OrderDirection.desc,
)
export const ExpenseFiltersSchema = createFiltersSchema(
    z.object({
        contactId: requiredUuid().optional(),
        search: z.string().optional(),
        dateFrom: requiredDateTime().optional(),
        dateTo: requiredDateTime().optional(),
        status: z
            .union([
                z.nativeEnum(ExpenseDocumentStatus),
                z.array(z.nativeEnum(ExpenseDocumentStatus)),
            ])
            .optional()
            .transform((value) =>
                value && typeof value === 'string' ? [value] : value,
            ),
    }),
)
export const PaginatedExpensesSchema = createPaginatedResponseSchema(
    ExpenseSchema,
    ExpenseOrderSchema,
    ExpenseFiltersSchema,
)

export type ExpenseOrderQuery = z.infer<typeof ExpenseOrderSchema>
export type ExpenseFiltersQuery = z.infer<typeof ExpenseFiltersSchema>
export type PaginatedExpensesRequest = {
    pagination: PaginationQuery
    order: ExpenseOrderQuery
    filters: ExpenseFiltersQuery
}
export type PaginatedExpensesResponse = z.infer<typeof PaginatedExpensesSchema>
