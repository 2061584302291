import {
    BaseCreateRevenueLineItemSchema,
    BaseRevenueLineItemSchema,
} from '~/schemas/base/base-revenue'
import type { z } from 'zod'

export const CreateProformaInvoiceLineItemSchema =
    BaseCreateRevenueLineItemSchema

export const ProformaInvoiceLineItemSchema = BaseRevenueLineItemSchema

export type ProformaInvoiceLineItemResponse = z.infer<
    typeof ProformaInvoiceLineItemSchema
>

export type CreateProformaInvoiceLineItemRequest = z.infer<
    typeof CreateProformaInvoiceLineItemSchema
>

export type UpdateProformaInvoiceLineItemRequest = z.infer<
    typeof CreateProformaInvoiceLineItemSchema
>
