import { z } from 'zod'
import { AccountSchema } from '~/schemas/account'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { TaxSchema } from '~/schemas/tax'
import { requiredString, requiredUuid } from '~/schemas/util'

export const CreateExpenseLineItemSchema = z.object({
    description: requiredString(),
    preTaxPrice: z.number(),
    taxId: z.string().nullable(),
    accountId: requiredUuid(),
})

export const ExpenseLineItemSchema = BaseEntitySchema.extend({
    description: z.string(),
    preTaxPrice: z.number(),
    tax: TaxSchema.nullable(),
    taxId: z.string().nullable(),
    account: AccountSchema.nullable(),
    accountId: requiredUuid(),
})

export type ExpenseLineItemResponse = z.infer<typeof ExpenseLineItemSchema>

export type CreateExpenseLineItemRequest = z.infer<
    typeof CreateExpenseLineItemSchema
>

export type UpdateExpenseLineItemRequest = z.infer<
    typeof CreateExpenseLineItemSchema
>
