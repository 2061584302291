import { z } from 'zod'
import { PaymentMethod } from '@prisma/client'
import type { CreatePaymentMethodSchema } from '~/schemas/common'

export const checkPaymentMethodOther = (
    {
        paymentMethod,
        paymentMethodOther,
        bankAccountId,
    }: z.infer<typeof CreatePaymentMethodSchema>,
    { addIssue }: z.RefinementCtx,
) => {
    if (paymentMethod === PaymentMethod.BANK_TRANSFER && !bankAccountId) {
        addIssue({
            code: z.ZodIssueCode.custom,
            path: ['bankAccountId'],
            message:
                "Must provide 'bankAccountId' if 'paymentMethod' is 'BANK_TRANSFER'",
        })
    }
    if (paymentMethod === PaymentMethod.OTHER && !paymentMethodOther) {
        addIssue({
            code: z.ZodIssueCode.custom,
            path: ['paymentMethodOther'],
            message:
                "Must provide 'paymentMethodOther' if 'paymentMethod' is 'OTHER'",
        })
    }
}
