import { z } from 'zod'
import { RevenueDocumentStatus } from '@prisma/client'

export const CreditNoteStatus = {
    ISSUED: RevenueDocumentStatus.ISSUED,
    SENT: RevenueDocumentStatus.SENT,
    PARTIALLY_PAID: RevenueDocumentStatus.PARTIALLY_PAID,
    PAID: RevenueDocumentStatus.PAID,
    OVERDUE: RevenueDocumentStatus.OVERDUE,
    UNCOLLECTIBLE: RevenueDocumentStatus.UNCOLLECTIBLE,
    VOID: RevenueDocumentStatus.VOID,
} as const
export type CreditNoteStatus =
    (typeof CreditNoteStatus)[keyof typeof CreditNoteStatus]

export const UpdateCreditNoteStatusSchema = z.object({
    status: z.nativeEnum(CreditNoteStatus),
})

export type UpdateCreditNoteStatusRequest = z.infer<
    typeof UpdateCreditNoteStatusSchema
>
