import { z } from 'zod'
import {
    BaseCreateRevenueSchema,
    BaseRevenueSchema,
} from '~/schemas/base/base-revenue'
import {
    CreatePaymentMethodSchema,
    PaymentMethodSchema,
} from '~/schemas/common'
import { checkPaymentMethodOther } from '~/schemas/util'
import {
    CreateInvoiceLineItemSchema,
    InvoiceLineItemSchema,
} from './invoice-line-item'
import { InvoiceStatus } from './invoice-status'

export const CreateInvoiceSchema = BaseCreateRevenueSchema.extend({
    status: z.nativeEnum(InvoiceStatus),
    lineItems: CreateInvoiceLineItemSchema.array().min(1),
})
    .extend(CreatePaymentMethodSchema.shape)
    .superRefine(checkPaymentMethodOther)

export const InvoiceSchema = BaseRevenueSchema.extend({
    status: z.nativeEnum(InvoiceStatus),
    lineItems: InvoiceLineItemSchema.array().min(1),
}).extend(PaymentMethodSchema.shape)

export type CreateInvoiceRequest = z.infer<typeof CreateInvoiceSchema>
export type UpdateInvoiceRequest = z.infer<typeof CreateInvoiceSchema>
export type InvoiceResponse = z.infer<typeof InvoiceSchema>

export const BasicInvoiceSchema = BaseRevenueSchema.omit({
    company: true,
    contact: true,
    lineItems: true,
    vatNonChargeReason: true,
})
    .extend({
        status: z.nativeEnum(InvoiceStatus),
    })
    .extend(CreatePaymentMethodSchema.shape)
export type BasicInvoiceResponse = z.infer<typeof BasicInvoiceSchema>
