import { z } from 'zod'
import { RevenueDocumentStatus } from '@prisma/client'

export const ProformaInvoiceStatus = {
    ISSUED: RevenueDocumentStatus.ISSUED,
    SENT: RevenueDocumentStatus.SENT,
    PARTIALLY_PAID: RevenueDocumentStatus.PARTIALLY_PAID,
    PAID: RevenueDocumentStatus.PAID,
    OVERDUE: RevenueDocumentStatus.OVERDUE,
    REJECTED: RevenueDocumentStatus.REJECTED,
    VOID: RevenueDocumentStatus.VOID,
} as const
export type ProformaInvoiceStatus =
    (typeof ProformaInvoiceStatus)[keyof typeof ProformaInvoiceStatus]

export const UpdateProformaInvoiceStatusSchema = z.object({
    status: z.nativeEnum(ProformaInvoiceStatus),
})

export type UpdateProformaInvoiceStatusRequest = z.infer<
    typeof UpdateProformaInvoiceStatusSchema
>
