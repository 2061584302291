import { z } from 'zod'

export enum AvailabilityEnum {
    Available = 'Available',
    Unavailable = 'Unavailable',
    MonitoringDisabled = 'Monitoring Disabled',
}

export const CountryVatStatusResponseSchema = z.object({
    vow: z
        .object({
            available: z.boolean().optional(),
        })
        .optional(),
    countries: z.array(
        z.object({
            countryCode: z.string().optional(),
            availability: z.nativeEnum(AvailabilityEnum).optional(),
        }),
    ),
})

export type CountryVatStatusResponse = z.infer<
    typeof CountryVatStatusResponseSchema
>
