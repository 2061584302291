import { z } from 'zod'
import { AccountCategory } from '@prisma/client'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { requiredString } from '~/schemas/util'
import type { TranslationKey } from '~/types/i18n'

const AccountCategoryRange = {
    [AccountCategory.FIXED_ASSETS]: [200, 299],
    [AccountCategory.ESTIMATES]: [400, 499],
    [AccountCategory.EXPENSES]: [600, 699],
    [AccountCategory.REVENUES]: [700, 799],
} as const

export const CreateAccountSchema = z
    .object({
        parentId: z.string().nullable(),
        category: z.nativeEnum(AccountCategory),
        number: z.number().min(100).max(999),
        subNumber: z
            .number()
            .min(0)
            .max(9999)
            .optional()
            .nullable()
            .default(null),
        name_bg: requiredString(),
        name_en: z.string().default(''),
        description: z.string().optional().default(''),
    })
    .refine(
        ({ category, number }) => {
            const minNumber = AccountCategoryRange[category][0]
            const maxNumber = AccountCategoryRange[category][1]

            return number >= minNumber && number <= maxNumber
        },
        ({ category }) => {
            const minNumber = AccountCategoryRange[category][0]
            const maxNumber = AccountCategoryRange[category][1]

            return {
                params: {
                    translationKey:
                        'validation.invalid-account-number' satisfies TranslationKey,
                    translationOptions: {
                        minNumber,
                        maxNumber,
                    },
                },
                path: ['number'],
            }
        },
    )

export interface IAccountSchema extends z.input<typeof BaseEntitySchema> {
    parentId: string | null
    category: AccountCategory
    number: number
    subNumber: number | null
    name_bg: string
    name_en: string
    displayName_bg?: string
    displayName_en?: string
    description: string
    hasTransactions?: boolean
    subaccounts?: IAccountSchema[]
}

export const AccountSchema: z.ZodType<IAccountSchema> = z.lazy(() =>
    BaseEntitySchema.extend({
        parentId: z.string().nullable(),
        category: z.nativeEnum(AccountCategory),
        number: z.number().min(100).max(999),
        subNumber: z.number().min(0).max(9999).nullable(),
        name_bg: requiredString(),
        name_en: z.string(),
        displayName_bg: z.string().default(''),
        displayName_en: z.string().default(''),
        description: z.string(),
        hasTransactions: z.boolean().optional(),
        subaccounts: AccountSchema.array().optional(),
    }),
)

export const AccountTree = z.object({
    [AccountCategory.FIXED_ASSETS]: AccountSchema.array(),
    [AccountCategory.ESTIMATES]: AccountSchema.array(),
    [AccountCategory.EXPENSES]: AccountSchema.array(),
    [AccountCategory.REVENUES]: AccountSchema.array(),
})

export type CreateAccountRequest = z.infer<typeof CreateAccountSchema>
export type UpdateAccountRequest = z.infer<typeof CreateAccountSchema>
export type AccountResponse = z.infer<typeof AccountSchema>
export type AccountTreeResponse = z.infer<typeof AccountTree>
