import { z } from 'zod'
import { RevenueDocumentStatus } from '@prisma/client'

export const DebitNoteStatus = {
    ISSUED: RevenueDocumentStatus.ISSUED,
    SENT: RevenueDocumentStatus.SENT,
    PARTIALLY_PAID: RevenueDocumentStatus.PARTIALLY_PAID,
    PAID: RevenueDocumentStatus.PAID,
    OVERDUE: RevenueDocumentStatus.OVERDUE,
    UNCOLLECTIBLE: RevenueDocumentStatus.UNCOLLECTIBLE,
    VOID: RevenueDocumentStatus.VOID,
} as const
export type DebitNoteStatus =
    (typeof DebitNoteStatus)[keyof typeof DebitNoteStatus]

export const UpdateDebitNoteStatusSchema = z.object({
    status: z.nativeEnum(DebitNoteStatus),
})

export type UpdateDebitNoteStatusRequest = z.infer<
    typeof UpdateDebitNoteStatusSchema
>
