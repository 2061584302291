import { z } from 'zod'
import { PaymentMethod } from '@prisma/client'
import { BankAccountSchema } from '~/schemas/bank-account'
import { requiredUuid } from '~/schemas/util'

export const CreatePaymentMethodSchema = z.object({
    paymentMethod: z.nativeEnum(PaymentMethod),
    paymentMethodOther: z.string().nullable(),
    bankAccountId: requiredUuid().nullable(),
})

export const PaymentMethodSchema = CreatePaymentMethodSchema.extend({
    bankAccount: BankAccountSchema.nullable(),
})
