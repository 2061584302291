import { z } from 'zod'
import { RevenueDocumentType } from '@prisma/client'
import {
    BaseRevenueLineItemSchema,
    BaseRevenueSchema,
} from '~/schemas/base/base-revenue'
import { PaymentMethodSchema } from '~/schemas/common/payment-method'

export const RevenueLineItemSchema = BaseRevenueLineItemSchema

export const RevenueSchema = BaseRevenueSchema.extend({
    type: z.nativeEnum(RevenueDocumentType),
    lineItems: RevenueLineItemSchema.array().min(1),
}).extend(PaymentMethodSchema.shape)

export type RevenueResponse = z.infer<typeof RevenueSchema>
export type RevenueLineItemResponse = z.infer<typeof RevenueLineItemSchema>
