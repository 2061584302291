import { z } from 'zod'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { requiredString } from '~/schemas/util'

export const CreateUnitSchema = z.object({
    name_bg: requiredString(),
    name_en: z.string().default(''),
})

export const UnitSchema = BaseEntitySchema.extend(CreateUnitSchema.shape)

export type CreateUnitRequest = z.infer<typeof CreateUnitSchema>
export type UpdateUnitRequest = z.infer<typeof CreateUnitSchema>
export type UnitResponse = z.infer<typeof UnitSchema>
