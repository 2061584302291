import { z } from 'zod'
import { requiredString } from '~/schemas/util'

export const SendEmailSchema = z.object({
    to: z.string().email(),
    subject: requiredString(),
    body: z.string(),
})

export type SendEmailRequest = z.infer<typeof SendEmailSchema>
