import { z } from 'zod'
import isBIC from 'validator/es/lib/isBIC'
import type { TranslationKey } from '~/types/i18n'

export const requiredBIC = () =>
    z.string().refine(isBIC, {
        params: {
            translationKey: 'validation.invalid-bic' satisfies TranslationKey,
        },
    })
