import { z } from 'zod'
import { ContactType } from '@prisma/client'
import isISO31661Alpha2 from 'validator/es/lib/isISO31661Alpha2'
import { OrderDirection } from '~/schemas/common'
import {
    createFiltersSchema,
    createOrderSchema,
    createPaginatedResponseSchema,
} from '~/schemas/util'
import { ContactSchema } from './contact'

export const ContactOrderSchema = createOrderSchema(
    z.enum(['name', 'createdAt']),
    'createdAt',
    OrderDirection.asc,
)

export const ContactFiltersSchema = createFiltersSchema(
    z.object({
        contactType: z.nativeEnum(ContactType).optional(),
        search: z.string().optional(),
        country: z
            .string()
            .refine((value) => isISO31661Alpha2(value), {
                message:
                    'The string is not a valid ISO 31661Alpha2 officially assigned country code.',
            })
            .optional(),
    }),
)
export const PaginatedContactsSchema = createPaginatedResponseSchema(
    ContactSchema,
    ContactOrderSchema,
    ContactFiltersSchema,
)

export type ContactOrderQuery = z.infer<typeof ContactOrderSchema>
export type ContactFiltersQuery = z.infer<typeof ContactFiltersSchema>
export type PaginatedContactsResponse = z.infer<typeof PaginatedContactsSchema>
