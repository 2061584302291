import { z } from 'zod'
import { requiredDateTime } from '~/schemas/util'

export enum Period {
    THIS_YEAR = 'this-year',
    PREVIOUS_YEAR = 'previous-year',
    LAST_12_MONTHS = 'last-12-months',
}

export const SummaryFiltersQuerySchema = z.object({
    period: z.nativeEnum(Period).default(Period.LAST_12_MONTHS),
    dateFrom: requiredDateTime().optional(),
    dateTo: requiredDateTime().optional(),
})

export const SummaryMonthSchema = z.object({
    year: z.number(),
    month: z.number(),
    assets: z.number(),
    revenue: z.number(),
    expenses: z.number(),
    result: z.number(),
})

export const SummarySchema = z.object({
    summary: z.array(SummaryMonthSchema),
    filters: SummaryFiltersQuerySchema,
    total: z.object({
        assets: z.number(),
        revenue: z.number(),
        expenses: z.number(),
        result: z.number(),
    }),
})

export const RevenueSummarySchema = z.object({
    filters: SummaryFiltersQuerySchema,
    revenueByStatus: z.record(z.number()),
    revenueByAccount: z.record(z.number()),
    totalRevenue: z.number(),
})

export const ExpenseSummarySchema = z.object({
    filters: SummaryFiltersQuerySchema,
    expenseByStatus: z.record(z.number()),
    expenseByAccount: z.record(z.number()),
    totalExpense: z.number(),
})

export type SummaryFiltersQuery = z.infer<typeof SummaryFiltersQuerySchema>
export type SummaryMonthResponse = z.infer<typeof SummaryMonthSchema>
export type SummaryResponse = z.infer<typeof SummarySchema>
export type RevenueSummaryResponse = z.infer<typeof RevenueSummarySchema>
export type ExpenseSummaryResponse = z.infer<typeof ExpenseSummarySchema>
