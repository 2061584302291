import { z } from 'zod'
import { OrderDirection } from '~/schemas/common'
import {
    createFiltersSchema,
    createOrderSchema,
    createPaginatedResponseSchema,
} from '~/schemas/util'
import { ItemSchema } from './item'

export const ItemOrderSchema = createOrderSchema(
    z.enum(['name_bg', 'rate', 'createdAt']),
    'createdAt',
    OrderDirection.asc,
)

export const ItemFiltersSchema = createFiltersSchema(
    z.object({
        search: z.string().optional(),
        active: z
            .string()
            .transform((value) => {
                switch (value) {
                    case 'true':
                        return true
                    case 'false':
                        return false
                }
            })
            .optional(),
    }),
)
export const PaginatedItemsSchema = createPaginatedResponseSchema(
    ItemSchema,
    ItemOrderSchema,
    ItemFiltersSchema,
)

export type ItemOrderQuery = z.infer<typeof ItemOrderSchema>
export type ItemFiltersQuery = z.infer<typeof ItemFiltersSchema>
export type PaginatedItemsResponse = z.infer<typeof PaginatedItemsSchema>
