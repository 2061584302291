import { z } from 'zod'
import { requiredString } from '~/schemas/util'

export const CreateCheckoutSessionRequestSchema = z.object({
    priceId: requiredString(),
    returnUrl: z.string().url(),
})

export const CompleteCheckoutSessionRequestSchema = z.object({
    priceId: requiredString(),
    subscriptionId: requiredString(),
})

export const CreateCustomerPortalSessionRequestSchema = z.object({
    returnUrl: z.string().url(),
})

export const GetSessionStatusQuerySchema = z.object({
    sessionId: requiredString(),
})

export type CreateCheckoutSessionRequest = z.infer<
    typeof CreateCheckoutSessionRequestSchema
>
export type CompleteCheckoutSessionRequest = z.infer<
    typeof CompleteCheckoutSessionRequestSchema
>
export type CreateCustomerPortalSessionRequest = z.infer<
    typeof CreateCustomerPortalSessionRequestSchema
>
export type GetSessionStatusQuery = z.infer<typeof GetSessionStatusQuerySchema>
