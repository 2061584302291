import { z } from 'zod'

export enum FileType {
    PDF = 'pdf',
    CSV = 'csv',
    XLSX = 'xlsx',
}

export const FileTypeQuerySchema = z.object({
    fileType: z.nativeEnum(FileType),
})

export type FileTypeQuery = z.infer<typeof FileTypeQuerySchema>
