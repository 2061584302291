import { z } from 'zod'
import isIBAN from 'validator/es/lib/isIBAN'
import type { TranslationKey } from '~/types/i18n'

export const requiredIBAN = () =>
    z.string().refine(isIBAN, {
        params: {
            translationKey: 'validation.invalid-iban' satisfies TranslationKey,
        },
    })
