import { z } from 'zod'
import { OrderDirection, type PaginationQuery } from '~/schemas/common'
import {
    createFiltersSchema,
    createOrderSchema,
    createPaginatedResponseSchema,
    requiredDateTime,
} from '~/schemas/util'
import { InvoiceSchema } from './invoice'
import { InvoiceStatus } from './invoice-status'

export const InvoiceOrderSchema = createOrderSchema(
    z.enum([
        'displayNumber',
        'contact.name',
        'issueDate',
        'totalInBaseCurrency',
        'createdAt',
    ]),
    'createdAt',
    OrderDirection.desc,
)
export const InvoiceFiltersSchema = createFiltersSchema(
    z.object({
        contactId: z.string().optional(),
        search: z.string().optional(),
        dateFrom: requiredDateTime().optional(),
        dateTo: requiredDateTime().optional(),
        status: z
            .union([
                z.nativeEnum(InvoiceStatus),
                z.array(z.nativeEnum(InvoiceStatus)),
            ])
            .optional()
            .transform((value) =>
                value && typeof value === 'string' ? [value] : value,
            ),
    }),
)
export const PaginatedInvoicesSchema = createPaginatedResponseSchema(
    InvoiceSchema,
    InvoiceOrderSchema,
    InvoiceFiltersSchema,
)

export type InvoiceOrderQuery = z.infer<typeof InvoiceOrderSchema>
export type InvoiceFiltersQuery = z.infer<typeof InvoiceFiltersSchema>
export type PaginatedInvoicesRequest = {
    pagination: PaginationQuery
    order: InvoiceOrderQuery
    filters: InvoiceFiltersQuery
}
export type PaginatedInvoicesResponse = z.infer<typeof PaginatedInvoicesSchema>
