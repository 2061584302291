import { z } from 'zod'
import isISO31661Alpha2 from 'validator/es/lib/isISO31661Alpha2'
import type { TranslationKey } from '~/types/i18n'

export const requiredCountryCode = () =>
    z
        .string()
        .refine(isISO31661Alpha2, {
            message:
                'The string is not a valid ISO 31661Alpha2 officially assigned country code.',
            params: {
                translationKey:
                    'validation.country-code-required' satisfies TranslationKey,
            },
        })
        .describe('Country code')
