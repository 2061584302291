import { z } from 'zod'

/**
 * Transform ids from string ids separated by comma into array of string ids
 *
 * @param {string} ids
 * @returns {array} array of ids
 */
export const DeleteManyByIdSchema = z.object({
    ids: z
        .string()
        .min(1)
        .max(100)
        .transform((idsAsString) => {
            const trimmed = idsAsString.trim()
            if (!trimmed.length) {
                return []
            }
            return trimmed.split(',')
        }),
})

export type DeleteManyById = z.infer<typeof DeleteManyByIdSchema>
