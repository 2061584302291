import type Stripe from 'stripe'
import {
    DocumentLabel,
    type CompleteCheckoutSessionRequest,
    type CreateCheckoutSessionRequest,
    type CreateCustomerPortalSessionRequest,
    type InvoiceResponse,
} from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/subscriptions`

export const subscriptions = {
    createCheckoutSession(
        companyId: string,
        body: CreateCheckoutSessionRequest,
    ) {
        return apiClient<string>(
            `${baseUrl(companyId)}/create-checkout-session`,
            {
                method: 'POST',
                body,
            },
        )
    },

    completeCheckoutSession(
        companyId: string,
        body: CompleteCheckoutSessionRequest,
    ) {
        return apiClient<void>(
            `${baseUrl(companyId)}/complete-checkout-session`,
            {
                method: 'POST',
                body,
            },
        )
    },

    createCustomerPortalSession(
        companyId: string,
        body: CreateCustomerPortalSessionRequest,
    ) {
        return apiClient<string>(
            `${baseUrl(companyId)}/create-customer-portal-session`,
            {
                method: 'POST',
                body,
            },
        )
    },

    getCheckoutStatus(companyId: string, sessionId: string) {
        return apiClient<Stripe.Response<Stripe.Checkout.Session>>(
            `${baseUrl(companyId)}/checkout-status`,
            {
                query: {
                    sessionId,
                },
            },
        )
    },

    getSubscriptionStatus(companyId: string) {
        return apiClient<Stripe.Response<Stripe.Subscription>>(
            `${baseUrl(companyId)}/subscription-status`,
        )
    },

    cancelSubscription(companyId: string) {
        return apiClient<Stripe.Response<Stripe.Subscription>>(
            `${baseUrl(companyId)}/cancel-subscription`,
            {
                method: 'POST',
            },
        )
    },

    findAllSubscriptionInvoices(companyId: string) {
        return apiClient<InvoiceResponse[]>(
            `${baseUrl(companyId)}/subscription-invoices`,
        )
    },

    async downloadPdf(
        companyId: string,
        subscriptionInvoiceId: string,
        documentLabel = DocumentLabel.Original,
    ) {
        const { _data, headers } = await apiClient.raw<Blob>(
            `${baseUrl(companyId)}/subscription-invoices/${subscriptionInvoiceId}/pdf`,
            {
                params: {
                    documentLabel,
                },
            },
        )

        const fileName = getFileNameFromHeaders(headers)

        if (!fileName) {
            $sentry.captureMessage('File name not found in headers')
        }

        downloadFileBlob(_data!, fileName || 'invoice.pdf')
    },
}
