import { z } from 'zod'
import {
    BaseCreateRevenueLineItemSchema,
    BaseRevenueLineItemSchema,
} from '~/schemas/base/base-revenue'
import { requiredUuid } from '~/schemas/util'

export const CreateCreditNoteLineItemSchema =
    BaseCreateRevenueLineItemSchema.extend({
        rate: z.number(),
        invoiceId: requiredUuid().nullish(),
    })

export const CreditNoteLineItemSchema = BaseRevenueLineItemSchema.extend({
    invoiceId: requiredUuid().nullable(),
})

export type CreditNoteLineItemResponse = z.infer<
    typeof CreditNoteLineItemSchema
>

export type CreateCreditNoteLineItemRequest = z.infer<
    typeof CreateCreditNoteLineItemSchema
>

export type UpdateCreditNoteLineItemRequest = z.infer<
    typeof CreateCreditNoteLineItemSchema
>
