import { z } from 'zod'

export enum Match {
    VALID = 'VALID',
    INVALID = 'INVALID',
    NOTPROCESSED = 'NOT_PROCESSED',
}

export const CheckVatRequestSchema = z.object({
    countryCode: z.string().optional(),
    vatNumber: z.string().optional(),
    requesterMemberStateCode: z.string().optional(),
    requesterNumber: z.string().optional(),
    traderName: z.string().optional(),
    traderStreet: z.string().optional(),
    traderPostalCode: z.string().optional(),
    traderCity: z.string().optional(),
    traderCompanyType: z.string().optional(),
})

export const CheckVatResponseSchema = z.object({
    countryCode: z.string().optional(),
    vatNumber: z.string().optional(),
    requestDate: z.date().optional(),
    valid: z.boolean().optional(),
    requestIdentifier: z.string().optional(),
    name: z.string().optional(),
    address: z.string().optional(),
    traderName: z.string().optional(),
    traderStreet: z.string().optional(),
    traderPostalCode: z.string().optional(),
    traderCity: z.string().optional(),
    traderCompanyType: z.string().optional(),
    traderNameMatch: z.nativeEnum(Match).optional(),
    traderStreetMatch: z.nativeEnum(Match).optional(),
    traderPostalCodeMatch: z.nativeEnum(Match).optional(),
    traderCityMatch: z.nativeEnum(Match).optional(),
    traderCompanyTypeMatch: z.nativeEnum(Match).optional(),
})

export type CheckVatRequest = z.infer<typeof CheckVatRequestSchema>
export type CheckVatResponse = z.infer<typeof CheckVatResponseSchema>
