import isISO4217 from 'validator/es/lib/isISO4217'
import { z } from 'zod'
import type { TranslationKey } from '~/types/i18n'

export const requiredCurrencyCode = () =>
    z
        .string()
        .refine(isISO4217, {
            params: {
                translationKey:
                    'validation.currency-code-required' satisfies TranslationKey,
            },
        })
        .describe('Currency code')
