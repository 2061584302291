import { z } from 'zod'
import { RevenueDocumentStatus, RevenueDocumentType } from '@prisma/client'
import { OrderDirection, type PaginationQuery } from '~/schemas/common'
import {
    createFiltersSchema,
    createOrderSchema,
    createPaginatedResponseSchema,
    requiredDateTime,
} from '~/schemas/util'
import { RevenueSchema } from './revenue'

export const RevenueOrderSchema = createOrderSchema(
    z.enum([
        'displayNumber',
        'contact.name',
        'issueDate',
        'totalInBaseCurrency',
        'createdAt',
    ]),
    'createdAt',
    OrderDirection.desc,
)
export const RevenueFiltersSchema = createFiltersSchema(
    z.object({
        contactId: z.string().optional(),
        search: z.string().optional(),
        status: z
            .union([
                z.nativeEnum(RevenueDocumentStatus),
                z.array(z.nativeEnum(RevenueDocumentStatus)),
            ])
            .optional()
            .transform((value) =>
                value && typeof value === 'string' ? [value] : value,
            ),
        dateFrom: requiredDateTime().optional(),
        dateTo: requiredDateTime().optional(),
        type: z
            .union([
                z.nativeEnum(RevenueDocumentType),
                z.array(z.nativeEnum(RevenueDocumentType)),
            ])
            .optional()
            .transform((value) =>
                value && typeof value === 'string' ? [value] : value,
            ),
    }),
)
export const PaginatedRevenuesSchema = createPaginatedResponseSchema(
    RevenueSchema,
    RevenueOrderSchema,
    RevenueFiltersSchema,
)

export type RevenueOrderQuery = z.infer<typeof RevenueOrderSchema>
export type RevenueFiltersQuery = z.infer<typeof RevenueFiltersSchema>
export type PaginatedRevenuesRequest = {
    pagination: PaginationQuery
    order: RevenueOrderQuery
    filters: RevenueFiltersQuery
}
export type PaginatedRevenuesResponse = z.infer<typeof PaginatedRevenuesSchema>
