import { z } from 'zod'

export enum OrderDirection {
    asc = 'asc',
    desc = 'desc',
}

/**
 * Generic Order Query Schema
 *
 * Do not use for specific order queries, use `createOrderSchema` instead
 */
export const OrderQuerySchema = z.object({
    order: z.nativeEnum(OrderDirection).optional(),
    orderBy: z.string().optional(),
})

export type OrderQuery = z.infer<typeof OrderQuerySchema>
