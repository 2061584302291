import { z } from 'zod'
import {
    BaseCreateRevenueSchema,
    BaseRevenueSchema,
} from '~/schemas/base/base-revenue'
import {
    CreatePaymentMethodSchema,
    PaymentMethodSchema,
} from '~/schemas/common'
import { checkPaymentMethodOther } from '~/schemas/util'
import {
    CreateProformaInvoiceLineItemSchema,
    ProformaInvoiceLineItemSchema,
} from './proforma-invoice-line-item'
import { ProformaInvoiceStatus } from './proforma-invoice-status'

export const CreateProformaInvoiceSchema = BaseCreateRevenueSchema.extend({
    status: z.nativeEnum(ProformaInvoiceStatus),
    lineItems: CreateProformaInvoiceLineItemSchema.array().min(1),
})
    .extend(CreatePaymentMethodSchema.shape)
    .superRefine(checkPaymentMethodOther)

export const ProformaInvoiceSchema = BaseRevenueSchema.extend({
    status: z.nativeEnum(ProformaInvoiceStatus),
    lineItems: ProformaInvoiceLineItemSchema.array().min(1),
}).extend(PaymentMethodSchema.shape)

export type CreateProformaInvoiceRequest = z.infer<
    typeof CreateProformaInvoiceSchema
>

export type UpdateProformaInvoiceRequest = z.infer<
    typeof CreateProformaInvoiceSchema
>

export type ProformaInvoiceResponse = z.infer<typeof ProformaInvoiceSchema>
