import { z } from 'zod'
import { RevenueDocumentStatus } from '@prisma/client'

export const InvoiceStatus = {
    ISSUED: RevenueDocumentStatus.ISSUED,
    SENT: RevenueDocumentStatus.SENT,
    PARTIALLY_PAID: RevenueDocumentStatus.PARTIALLY_PAID,
    PAID: RevenueDocumentStatus.PAID,
    OVERDUE: RevenueDocumentStatus.OVERDUE,
    UNCOLLECTIBLE: RevenueDocumentStatus.UNCOLLECTIBLE,
    VOID: RevenueDocumentStatus.VOID,
} as const
export type InvoiceStatus = (typeof InvoiceStatus)[keyof typeof InvoiceStatus]

export const UpdateInvoiceStatusSchema = z.object({
    status: z.nativeEnum(InvoiceStatus),
})

export type UpdateInvoiceStatusRequest = z.infer<
    typeof UpdateInvoiceStatusSchema
>
