import { z } from 'zod'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { requiredString } from '~/schemas/util'

export const CreateTaxSchema = z.object({
    name: requiredString(),
    percentage: z.number(),
})

export const TaxSchema = BaseEntitySchema.extend(CreateTaxSchema.shape)

export type CreateTaxRequest = z.infer<typeof CreateTaxSchema>
export type UpdateTaxRequest = z.infer<typeof CreateTaxSchema>
export type TaxResponse = z.infer<typeof TaxSchema>
