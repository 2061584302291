import { z } from 'zod'
import { OrderDirection } from '~/schemas/common/order-query'

/**
 * Order Schema factory, where T is an enum of the allowed order fields
 *
 * @param orderByFields - The enum of the allowed order fields
 * @param defaultOrderByField - The default order field
 * @param defaultDirection - The default order direction
 * @returns The Order Schema
 */
export function createOrderSchema<T extends z.ZodEnum<any>>(
    orderByFields: T,
    defaultOrderByField: keyof T['enum'],
    defaultDirection: OrderDirection,
) {
    return z.object({
        order: z
            .nativeEnum(OrderDirection)
            .optional()
            .transform((value) => value ?? defaultDirection),
        orderBy: orderByFields
            .optional()
            .transform((value) => value ?? defaultOrderByField),
    })
}

export type OrderSchema = ReturnType<typeof createOrderSchema<z.ZodEnum<any>>>
