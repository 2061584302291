import { z } from 'zod'
import isStrongPassword from 'validator/es/lib/isStrongPassword'
import { Locale } from '@prisma/client'
import isJWT from 'validator/es/lib/isJWT'
import { requiredString } from '~/schemas/util'

const passwordConfig = {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 0,
}

export const AcceptInviteSchema = z.object({
    invitationToken: requiredString().refine((val) => isJWT(val)),
})

export const ConfirmEmailSchema = z.object({
    token: requiredString().refine((val) => isJWT(val)),
})

export const ForgotPasswordSchema = z.object({
    email: requiredString().email(),
})

export const LoginSchema = z.object({
    email: requiredString().email(),
    password: requiredString(),
})

export const RefreshTokensSchema = z.object({
    refreshToken: requiredString().refine((val) => isJWT(val)),
})

export const CreateUserSchema = z.object({
    email: requiredString().email(),
    password: requiredString().refine((val) =>
        isStrongPassword(val, passwordConfig),
    ),
    firstName: requiredString(),
    lastName: requiredString(),
    locale: z.nativeEnum(Locale),
})

export const CreateUserByInviteSchema = CreateUserSchema.extend({
    invitationToken: requiredString().refine((val) => isJWT(val)),
})

export const ResetPasswordSchema = z.object({
    token: requiredString().refine((val) => isJWT(val)),
    newPassword: requiredString(),
    newPasswordConfirm: requiredString(),
})

export const TokensSchema = z.object({
    accessToken: z.string(),
    refreshToken: z.string(),
})

export const ResendEmailConfirmationSchema = ForgotPasswordSchema

export type AcceptInviteRequest = z.infer<typeof AcceptInviteSchema>
export type ForgotPasswordRequest = z.infer<typeof ForgotPasswordSchema>
export type ConfirmEmailRequest = z.infer<typeof ConfirmEmailSchema>
export type LoginRequest = z.infer<typeof LoginSchema>
export type RefreshTokensRequest = z.infer<typeof RefreshTokensSchema>
export type CreateUserByInviteRequest = z.infer<typeof CreateUserByInviteSchema>

export type CreateUserRequest = z.infer<typeof CreateUserSchema>
export type ResetPasswordRequest = z.infer<typeof ResetPasswordSchema>
export type TokensResponse = z.infer<typeof TokensSchema>
export type ResendEmailConfirmationRequest = z.infer<
    typeof ResendEmailConfirmationSchema
>
