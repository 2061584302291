import { z } from 'zod'
import { ExpenseDocumentStatus } from '@prisma/client'

export const UpdateExpenseStatusSchema = z.object({
    status: z.nativeEnum(ExpenseDocumentStatus),
})

export type UpdateExpenseStatusRequest = z.infer<
    typeof UpdateExpenseStatusSchema
>
