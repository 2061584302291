import { z } from 'zod'
import { PaginationSchema } from '~/schemas/common/pagination-query'
import type { OrderSchema } from './order'
import type { FiltersSchema } from './filters'

/**
 * Paginated Response Schema factory.
 *
 * @param dataSchema - The schema for the data items
 * @param orderSchema - The schema for the order fields
 * @param filtersSchema - The schema for the filters
 * @returns The Paginated Response Schema
 */
export const createPaginatedResponseSchema = <
    T extends z.ZodObject<any>,
    U extends OrderSchema,
    V extends FiltersSchema,
>(
    dataSchema: T,
    orderSchema: U,
    filtersSchema: V,
) =>
    z.object({
        data: z.array(dataSchema),
        pagination: PaginationSchema,
        order: orderSchema,
        filters: filtersSchema,
        count: z.number(),
    })

export type PaginatedResponseSchema = ReturnType<
    typeof createPaginatedResponseSchema<
        z.ZodObject<any>,
        OrderSchema,
        FiltersSchema
    >
>
