import { z } from 'zod'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { requiredString } from '~/schemas/util'

export const FileSchema = BaseEntitySchema.extend({
    name: requiredString(),
    mimetype: requiredString(),
    size: z.number(),
    url: requiredString(),
})

export type FileResponse = z.infer<typeof FileSchema>
