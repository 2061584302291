import { z } from 'zod'
import {
    requiredString,
    requiredDateTime,
    requiredCurrencyCode,
} from '~/schemas/util'

export const ExchangeRateSchema = z.object({
    base: requiredCurrencyCode(),
    target: requiredCurrencyCode(),
    date: requiredDateTime(),
    rate: z.number(),
    fromBnb: z.boolean().optional(),
})

export const SupportedCurrencySchema = z.object({
    code: requiredCurrencyCode(),
    name_bg: requiredString(),
    name_en: requiredString(),
    namePlural_bg: requiredString(),
    namePlural_en: requiredString(),
    symbol: requiredString(),
    symbolNative: requiredString(),
    decimalDigits: z.number(),
    rounding: z.number(),
})

export type ExchangeRateResponse = z.infer<typeof ExchangeRateSchema>
export type SupportedCurrencyResponse = z.infer<typeof SupportedCurrencySchema>
