import { z } from 'zod'
import { RevenueDocumentType } from '@prisma/client'

export const NextDocumentNumberQuerySchema = z.object({
    documentType: z.nativeEnum(RevenueDocumentType),
    prefix: z.string().min(0).max(9),
})
export const NextDocumentNumberResponseSchema = z.object({
    nextNumber: z.number().nonnegative(),
})

export type NextDocumentNumberQuery = z.infer<
    typeof NextDocumentNumberQuerySchema
>

export type NextDocumentNumberResponse = z.infer<
    typeof NextDocumentNumberResponseSchema
>
