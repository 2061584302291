import { z } from 'zod'
import { AccountCategory } from '@prisma/client'
import { createFiltersSchema } from '~/schemas/util'

export const AccountFiltersSchema = createFiltersSchema(
    z.object({
        category: z
            .union([
                z.nativeEnum(AccountCategory),
                z.array(z.nativeEnum(AccountCategory)),
            ])
            .optional()
            .transform((value) =>
                value && typeof value === 'string' ? [value] : value,
            ),
    }),
)

export type AccountFiltersQuery = z.infer<typeof AccountFiltersSchema>
