import { z } from 'zod'
import {
    ExpenseDocumentStatus,
    ExpenseDocumentType,
    TaxMethod,
} from '@prisma/client'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import {
    checkPaymentMethodOther,
    requiredString,
    requiredDateTime,
    requiredCurrencyCode,
    requiredUuid,
    optionalDateTime,
} from '~/schemas/util'
import { ContactSchema } from '~/schemas/contact'
import { FileSchema } from '~/schemas/file'
import {
    CreatePaymentMethodSchema,
    PaymentMethodSchema,
} from '~/schemas/common'
import {
    CreateExpenseLineItemSchema,
    ExpenseLineItemSchema,
} from './expense-line-item'

export const CreateExpenseSchema = z
    .object({
        type: z.nativeEnum(ExpenseDocumentType),
        contactId: requiredUuid(),
        displayNumber: requiredString(),
        issueDate: requiredDateTime(),
        taxableEventDate: requiredDateTime(),
        dueDate: optionalDateTime(),
        currencyCode: requiredCurrencyCode(),
        exchangeRate: z.number().default(1),
        notes: z.string().default(''),
        isVatRegistered: z.boolean(),
        taxMethod: z.nativeEnum(TaxMethod),
        status: z.nativeEnum(ExpenseDocumentStatus),
        lineItems: CreateExpenseLineItemSchema.array().min(1),
    })
    .extend(CreatePaymentMethodSchema.shape)
    .superRefine(checkPaymentMethodOther)

export const ExpenseSchema = BaseEntitySchema.extend({
    type: z.nativeEnum(ExpenseDocumentType),
    displayNumber: z.string(),
    issueDate: requiredDateTime(),
    dueDate: optionalDateTime(),
    taxableEventDate: requiredDateTime(),
    currencyCode: requiredCurrencyCode(),
    notes: z.string().default(''),
    subtotal: z.number(),
    subtotalInBaseCurrency: z.number(),
    taxTotal: z.number(),
    taxTotalInBaseCurrency: z.number(),
    total: z.number(),
    totalInBaseCurrency: z.number(),
    exchangeRate: z.number().default(1),
    isVatRegistered: z.boolean(),
    taxMethod: z.nativeEnum(TaxMethod),
    attachments: z.array(FileSchema),
    status: z.nativeEnum(ExpenseDocumentStatus),
    contact: ContactSchema,
    contactId: requiredUuid(),
    lineItems: ExpenseLineItemSchema.array().min(1),
}).extend(PaymentMethodSchema.shape)

export type CreateExpenseRequest = z.infer<typeof CreateExpenseSchema>
export type UpdateExpenseRequest = z.infer<typeof CreateExpenseSchema>
export type ExpenseResponse = z.infer<typeof ExpenseSchema>
