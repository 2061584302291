import { z } from 'zod'
import { Locale } from '@prisma/client'

export const UpdateDocumentLocaleSchema = z.object({
    locale: z.nativeEnum(Locale),
})

export type UpdateDocumentLocaleRequest = z.infer<
    typeof UpdateDocumentLocaleSchema
>
