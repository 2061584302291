import { z } from 'zod'
import { requiredDateTime, requiredString } from '~/schemas/util'
import { BaseEntitySchema } from '~/schemas/base/base-entity'

/**
 * Represents invoices external to eFirma, used when issuing credit/debit notes for invoices not in our database.
 */
export const CreateExternalInvoiceSchema = z.object({
    invoiceNumber: requiredString(),
    invoiceDate: requiredDateTime(),
})

export const ExternalInvoiceSchema = BaseEntitySchema.extend(
    CreateExternalInvoiceSchema.shape,
)

export type CreateExternalInvoiceRequest = z.infer<
    typeof CreateExternalInvoiceSchema
>

export type ExternalInvoiceResponse = z.infer<typeof ExternalInvoiceSchema>
