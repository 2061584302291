import { z } from 'zod'
import { OrderDirection, type PaginationQuery } from '~/schemas/common'
import {
    createFiltersSchema,
    createOrderSchema,
    createPaginatedResponseSchema,
    requiredDateTime,
} from '~/schemas/util'
import { CreditNoteSchema } from './credit-note'
import { CreditNoteStatus } from './credit-note-status'

export const CreditNoteOrderSchema = createOrderSchema(
    z.enum([
        'displayNumber',
        'contact.name',
        'issueDate',
        'totalInBaseCurrency',
        'createdAt',
    ]),
    'createdAt',
    OrderDirection.desc,
)

export const CreditNoteFiltersSchema = createFiltersSchema(
    z.object({
        contactId: z.string().optional(),
        search: z.string().optional(),
        dateFrom: requiredDateTime().optional(),
        dateTo: requiredDateTime().optional(),
        status: z
            .union([
                z.nativeEnum(CreditNoteStatus),
                z.array(z.nativeEnum(CreditNoteStatus)),
            ])
            .optional()
            .transform((value) =>
                value && typeof value === 'string' ? [value] : value,
            ),
    }),
)
export const PaginatedCreditNotesSchema = createPaginatedResponseSchema(
    CreditNoteSchema,
    CreditNoteOrderSchema,
    CreditNoteFiltersSchema,
)

export type CreditNoteOrderQuery = z.infer<typeof CreditNoteOrderSchema>
export type CreditNoteFiltersQuery = z.infer<typeof CreditNoteFiltersSchema>
export type PaginatedCreditNotesRequest = {
    pagination: PaginationQuery
    order: CreditNoteOrderQuery
    filters: CreditNoteFiltersQuery
}
export type PaginatedCreditNotesResponse = z.infer<
    typeof PaginatedCreditNotesSchema
>
