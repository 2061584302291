import { z } from 'zod'

export enum DocumentLabel {
    Original = 'original',
    Copy = 'copy',
}

export const DocumentLabelQuerySchema = z.object({
    documentLabel: z.nativeEnum(DocumentLabel).default(DocumentLabel.Original),
})

export type DocumentLabelQuery = z.infer<typeof DocumentLabelQuerySchema>
