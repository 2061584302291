import { z } from 'zod'
import { BaseEntitySchema } from '~/schemas/base/base-entity'
import { requiredString } from '~/schemas/util'

export const CreateVatNonChargeReasonSchema = z.object({
    text_bg: requiredString(),
    text_en: z.string().default(''),
})

export const VatNonChargeReasonSchema = BaseEntitySchema.extend(
    CreateVatNonChargeReasonSchema.shape,
)

export type CreateVatNonChargeReasonRequest = z.infer<
    typeof CreateVatNonChargeReasonSchema
>

export type VatNonChargeReasonResponse = z.infer<
    typeof VatNonChargeReasonSchema
>
