import { z } from 'zod'
import {
    BaseCreateRevenueSchema,
    BaseRevenueSchema,
} from '~/schemas/base/base-revenue'
import {
    CreateExternalInvoiceSchema,
    CreatePaymentMethodSchema,
    ExternalInvoiceSchema,
    PaymentMethodSchema,
} from '~/schemas/common'
import { BasicInvoiceSchema } from '~/schemas/invoice'
import { checkPaymentMethodOther, requiredString } from '~/schemas/util'
import {
    CreateDebitNoteLineItemSchema,
    DebitNoteLineItemSchema,
} from './debit-note-line-item'
import { DebitNoteStatus } from './debit-note-status'

export const CreateDebitNoteSchema = BaseCreateRevenueSchema.extend({
    status: z.nativeEnum(DebitNoteStatus),
    lineItems: CreateDebitNoteLineItemSchema.array().min(1),
    issueReason: requiredString(),
    invoices: z
        .array(BasicInvoiceSchema)
        .min(1)
        .or(z.array(CreateExternalInvoiceSchema).min(1)),
    areInvoicesInternal: z.boolean(),
})
    .extend(CreatePaymentMethodSchema.shape)
    .superRefine(checkPaymentMethodOther)

export const DebitNoteSchema = BaseRevenueSchema.extend({
    status: z.nativeEnum(DebitNoteStatus),
    lineItems: DebitNoteLineItemSchema.array().min(1),
    issueReason: requiredString(),
    invoices: z
        .array(BasicInvoiceSchema)
        .min(1)
        .or(z.array(ExternalInvoiceSchema).min(1)),
    areInvoicesInternal: z.boolean(),
}).extend(PaymentMethodSchema.shape)

export type CreateDebitNoteRequest = z.infer<typeof CreateDebitNoteSchema>

export type UpdateDebitNoteRequest = z.infer<typeof CreateDebitNoteSchema>

export type DebitNoteResponse = z.infer<typeof DebitNoteSchema>
