import {
    BaseCreateRevenueLineItemSchema,
    BaseRevenueLineItemSchema,
} from '~/schemas/base/base-revenue'
import { requiredUuid } from '~/schemas/util'
import type { z } from 'zod'

export const CreateDebitNoteLineItemSchema =
    BaseCreateRevenueLineItemSchema.extend({
        invoiceId: requiredUuid().nullish(),
    })

export const DebitNoteLineItemSchema = BaseRevenueLineItemSchema.extend({
    invoiceId: requiredUuid().nullable(),
})

export type DebitNoteLineItemResponse = z.infer<typeof DebitNoteLineItemSchema>

export type CreateDebitNoteLineItemRequest = z.infer<
    typeof CreateDebitNoteLineItemSchema
>

export type UpdateDebitNoteLineItemRequest = z.infer<
    typeof CreateDebitNoteLineItemSchema
>
