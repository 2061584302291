import { z } from 'zod'
import { Locale } from '@prisma/client'
import { requiredString } from '~/schemas/util'
import { VatNonChargeReasonSchema } from '~/schemas/vat-non-charge-reasons'

export const UpdateContactSettingsSchema = z.object({
    invoiceNotes: z.string().default('').optional(),
    invoiceLocale: z.nativeEnum(Locale).optional(),
    vatNonChargeReasonId: z.string().nullable(),
})

export const ContactSettingsSchema = z.object({
    id: requiredString(),
    invoiceNotes: z.string(),
    invoiceLocale: z.nativeEnum(Locale),
    vatNonChargeReason: VatNonChargeReasonSchema.nullable(),
    vatNonChargeReasonId: z.string().nullable(),
})

export type UpdateContactSettingsRequest = z.infer<
    typeof UpdateContactSettingsSchema
>

export type ContactSettingsResponse = z.infer<typeof ContactSettingsSchema>
