import { z } from 'zod'

export enum ExportVariant {
    SHORT = 'short',
    DETAILED = 'detailed',
}

export const ExportVariantQuerySchema = z.object({
    variant: z.nativeEnum(ExportVariant),
})

export type ExportVariantQuery = z.infer<typeof ExportVariantQuerySchema>
