import { z } from 'zod'
import { requiredCountryCode } from '~/schemas/util'

export const CountrySchema = z.object({
    code: requiredCountryCode(),
    name_bg: z.string().trim().describe('Country name BG'),
    name_en: z.string().trim().describe('Country name EN'),
    is_eu: z.boolean().optional().describe('Is country in EU'),
})

export type CountryResponse = z.infer<typeof CountrySchema>
