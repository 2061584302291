import { z } from 'zod'

const DEFAULT_PAGE_SIZE = 20
const MAX_PAGE_SIZE = 100

// GET /resource?page=1&pageSize=10
export const PaginationQuerySchema = z.object({
    page: z
        .string()
        .optional()
        .transform((value) => Number(value) || 1),
    pageSize: z
        .string()
        .max(MAX_PAGE_SIZE)
        .optional()
        .transform((value) => Number(value) || DEFAULT_PAGE_SIZE),
})

export const PaginationSchema = z.object({
    total: z.number(),
    page: z.number(),
    pages: z.number(),
    pageSize: z.number(),
})

export type PaginationQuery = z.infer<typeof PaginationQuerySchema>
export type PaginationResponse = z.infer<typeof PaginationSchema>
